import _schedulerTracingDevelopment from "./cjs/scheduler-tracing.development.js";
export { _schedulerTracingDevelopment as default };
export const __interactionsRef = _schedulerTracingDevelopment.__interactionsRef,
      __subscriberRef = _schedulerTracingDevelopment.__subscriberRef,
      unstable_clear = _schedulerTracingDevelopment.unstable_clear,
      unstable_getCurrent = _schedulerTracingDevelopment.unstable_getCurrent,
      unstable_getThreadID = _schedulerTracingDevelopment.unstable_getThreadID,
      unstable_subscribe = _schedulerTracingDevelopment.unstable_subscribe,
      unstable_trace = _schedulerTracingDevelopment.unstable_trace,
      unstable_unsubscribe = _schedulerTracingDevelopment.unstable_unsubscribe,
      unstable_wrap = _schedulerTracingDevelopment.unstable_wrap;